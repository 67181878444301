import React, { useState } from 'react'
import { Col, Container, Row } from 'react-bootstrap'
import { AiFillTwitterCircle, AiFillInstagram } from 'react-icons/ai';
import { FaFacebook } from 'react-icons/fa';


import Modale from '../modal';
import Cookies from 'universal-cookie';
import './style.css'

export default function Footer() {
    const [modalShow, setModalShow] = useState(false);
    const cookie = new Cookies();

    const helpSupport = [
        {
            id: 1,
            title: 'Help Center',
        },
        {
            id: 2,
            title: 'Contact Us',
        },
        {
            id: 3,
            title: 'Privacy Policy',
        },
        {
            id: 4,
            title: 'FAQ',
        },

    ];

    const links = [
        {
            id: 1,
            title: 'Home'
        },
        {
            id: 2,
            title: 'Las Vegas Rules'
        },
        {
            id: 3,
            title: 'Reduced Juice'
        },
        {
            id: 4,
            title: 'Dictionary'
        },

    ]

    return (
        <div style={{ backgroundColor: '#212529', height: 'auto', display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center' }}>
            {/*
            <div style={{ color: 'white', display: 'flex', alignItems: 'center', justifyContent: 'center', paddingTop: 24 }}  >
                <FaFacebook style={{ color: 'white', marginRight: 24, cursor: 'pointer' }} size={40} onClick={() => setModalShow(true)} />
                <AiFillInstagram style={{ color: 'white', marginRight: 24, cursor: 'pointer' }} size={42} onClick={() => setModalShow(true)} />
                <AiFillTwitterCircle style={{ color: 'white', cursor: 'pointer' }} size={43} onClick={() => setModalShow(true)} />
            </div>
            */}
            <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', flexDirection: 'column', marginTop: 32 }}>
                <p style={{ color: 'white', cursor: 'pointer', margin: 0 }} onClick={() => setModalShow(true)}>Info.Support.Marketing</p>
                <p style={{ color: 'white', cursor: 'pointer', margin: 0 }} onClick={() => setModalShow(true)}>Term of use.Privacy policy</p>
                <p style={{ fontSize: 12, textAlign: 'center', color: 'white', width: '50%', marginTop: 32, opacity: 0.5 }}>
                    18+ Know When To Stop. Don’t Go Over The Top. Gamble Responsibly. Think! About Your Choices. Check Gambling Help on www.gamblinghelponline.org.au - www.gambleaware.nsw.gov.au
                </p>
                <p style={{ color: 'white', cursor: 'pointer', margin: '0px 0px 24px 0px', opacity: '0.6' }} >Copyright © 2022 - All right reserved.</p>

            </div>

            <Modale
                show={modalShow}
                onHide={() => setModalShow(false)}
                signin={cookie.get('token') ? null : true}
            />
        </div >
    )
}