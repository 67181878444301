import React, { useState } from 'react'
import Modale from '../modal'
import './style.css'


export default function SigninText(props) {
    const [modalShow, setModalShow] = useState(false);

    return (
        <>
            <p className='signinText' onClick={() => setModalShow(true)}>Log in</p>
            <Modale
                show={modalShow}
                onHide={() => setModalShow(false)}
                setModalShow={setModalShow}
                signin={props.signin}
            />
        </>
    )
}
