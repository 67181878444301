// import React from 'react';

export const sidebarData = [
    {
        id: 1,
        title: "Point Spreads",
    },
    {
        id: 2,
        title: "Money Lines",
    },
    {
        id: 3,
        title: "Totals",
    },
    {
        id: 4,
        title: "European Prices",
    },
    {
        id: 5,
        title: "Quarter Lines",
    },
    {
        id: 6,
        title: "Half-time Lines",
    },
    {
        id: 7,
        title: "Parlays",
    },
    {
        id: 8,
        title: "Progressive Parlays",
    },
    {
        id: 9,
        title: "Teasers",
    },
    {
        id: 10,
        title: "If Bets",
    },
    {
        id: 11,
        title: "Futures",
    },
    {
        id: 12,
        title: "Proposotions",
    },
]

