import { Carousel } from 'react-bootstrap'
import React from 'react'
import './style.css'
import SideBar from '../sidebar';

export default function Slideshow({ news }) {


    return (
        <Carousel indicators={false} interval={2000} >
            <Carousel.Item style={{ width: '100%' }} >
                <div
                    className=" carouselItem"
                    style={{
                        backgroundImage: `url(${require("../../assets/images/1.webp")})`,
                    }}
                ></div>
            </Carousel.Item>
            <Carousel.Item style={{ width: '100%' }} >
                <div
                    className=" carouselItem"
                    style={{
                        backgroundImage: `url(${require("../../assets/images/2.webp")})`,
                    }}
                ></div>
            </Carousel.Item>
            <Carousel.Item style={{ width: '100%' }} >
                <div
                    className=" carouselItem"
                    style={{
                        backgroundImage: `url(${require("../../assets/images/3.webp")})`,
                    }}
                ></div>
            </Carousel.Item>
            <Carousel.Item style={{ width: '100%' }} >
                <div
                    className=" carouselItem"
                    style={{
                        backgroundImage: `url(${require("../../assets/images/4.webp")})`,
                    }}
                ></div>
            </Carousel.Item>
            <Carousel.Item style={{ width: '100%' }} >
                <div
                    className=" carouselItem"
                    style={{
                        backgroundImage: `url(${require("../../assets/images/5.webp")})`,
                    }}
                ></div>
            </Carousel.Item>
            <Carousel.Item style={{ width: '100%' }} >
                <div
                    className=" carouselItem"
                    style={{
                        backgroundImage: `url(${require("../../assets/images/6.webp")})`,
                    }}
                ></div>
            </Carousel.Item>
        </Carousel>

    )
}

