// import { Popover } from 'bootstrap';
import React, { useState } from 'react'
import { Nav, Navbar, Container, Dropdown, Row } from 'react-bootstrap'
import './style.css';
import DropDown from '../dropdown';
import Buttons from '../button';
import Cookies from 'universal-cookie';
import Modale from '../modal';
import { navLinks } from './navLinks';
import SigninText from '../signinText';
import useViewport from '../../hook/useViewport'


export default function Navigation() {
    const cookie = new Cookies();
    const { width } = useViewport();
    const [modalShow, setModalShow] = useState(false);
    const [bg, setBg] = useState(false);
    const changeColor = () => {
        if (window.scrollY >= 72) {
            setBg(true)
        } else {
            setBg(false)
        }
    }
    window.addEventListener('scroll', changeColor);

    // const token = true;
    return (

        <Navbar expand="md" fixed='top' style={{ padding: 0 }}>
            <Container className={bg ? 'navContainer active' : 'navContainer'} fluid >
                <Navbar.Brand href="/">
                    <div className="logo"></div>
                </Navbar.Brand>
                {
                    width < 900 ?
                        <>
                            <Navbar.Toggle className='toggleButton' aria-controls="basic-navbar-nav" />
                        </>
                        :
                        null
                }
                <Navbar.Collapse id="navbarScroll">
                    <Nav className="me-auto" ></Nav>

                    <Nav
                        className=" my-2 my-lg-0 linkContainer"
                    >
                        <Nav.Link className='navLink2' onClick={() => setModalShow(true)}>Home</Nav.Link>
                        <Nav.Link className='navLink2' onClick={() => setModalShow(true)}>Bet Now</Nav.Link>
                        <Dropdown className='sportDropdownContainer' >
                            <Dropdown.Toggle className="sportDropdown" id="dropdown-basic" style={{ backgroundColor: "transparent" }}>
                                Sports
                            </Dropdown.Toggle>
                            <Dropdown.Menu className='dropDownMenu'  >
                                {navLinks?.map((data) =>
                                    <Dropdown.Item key={data.id} className={data.cName} onClick={() => setModalShow(true)}>{data.link}</Dropdown.Item>
                                )}
                            </Dropdown.Menu>
                        </Dropdown>
                        <Nav.Link className='navLink2' onClick={() => setModalShow(true)}>Upcoming games</Nav.Link>
                        <Nav.Link className='navLink22' onClick={() => setModalShow(true)} >contact us</Nav.Link>


                    </Nav>
                    {
                        cookie.get('token') ?
                            <DropDown />
                            :
                            <>
                                {/* <div className="vl"></div> */}
                                <SigninText signin={true} />
                                <Buttons text='register' kind="orange" signin={false} />
                                {/* <Buttons text='sign in' kind="none" signin={true} /> */}

                            </>

                    }
                    <Modale
                        show={modalShow}
                        onHide={() => setModalShow(false)}
                        signin={cookie.get('token') ? null : true}
                    />
                </Navbar.Collapse>
            </Container>
        </Navbar >

    )
}
