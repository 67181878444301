import React from 'react'
import axios from 'axios';
import { useEffect, useState } from 'react'
import { Col, Table, Container } from 'react-bootstrap';
import moment from 'moment';
import Modale from '../modal';
import './style.css'
import shuffledArray from '../../hook/shuffledArray';

export default function CanceledGamesData(props) {
    const [upcoming, setUpcoming] = useState([]);
    const [canceled, setCanceled] = useState([]);
    const [modalShow, setModalShow] = useState(false);

    useEffect(() => {
        axios.get('/api/results')
            .then(async response => {
                await setUpcoming(response.data.data[0].results)
                await setCanceled(upcoming.filter((data) => data.status === 'canceled'))
            })
            .catch(error => console.log(error));
    }, []);
    var shuffledarray = shuffledArray(upcoming);

    return (
        <>
            {canceled.length !== 0 ?
                <>
                    <Container style={{ backgroundColor: '#212529', padding: '16px', borderRadius: 16 }}>
                        <h4 style={{ color: 'white', marginBottom: 16 }}>Canceled Games</h4>
                        <Col className='canceledTableContainer' >
                            <Table responsive="sm" striped variant="dark" hover style={{ textAlign: 'center' }}>
                                <thead>
                                    <tr style={{ borderBottomStyle: 'hidden' }}>
                                        <th>Date</th>
                                        <th>League</th>
                                        <th>Home</th>
                                        <th>Away</th>

                                    </tr>
                                </thead>
                                <tbody >
                                    {
                                        shuffledarray.filter((data) => data.status === 'canceled')?.map((data, index) => (
                                            <tr key={index}>
                                                <td style={{ fontWeight: 500 }}>
                                                    {moment().format('d-MMMM-YYYY') + ' at ' + moment().format('hh:mm A')}
                                                </td>
                                                <td style={{ fontWeight: 500 }}>
                                                    {data.details.league}
                                                </td>
                                                <td>
                                                    <p style={{ fontSize: 14, lineHeight: 1.3, fontWeight: 500, margin: 0 }}>{data.teams.home.team}</p>
                                                </td>
                                                <td>
                                                    <p style={{ fontSize: 14, lineHeight: 1.3, fontWeight: 500, margin: 0 }}>{data.teams.away.team}</p>
                                                </td>
                                            </tr>
                                        ))
                                    }
                                </tbody>
                            </Table>
                        </Col>
                        <Modale
                            show={modalShow}
                            onHide={() => setModalShow(false)}
                            signin={props.signin}
                        />
                    </Container>
                </> : null}
        </>
    )
}
