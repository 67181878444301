// import react from 'react'


export const navLinks = [
    {
        id: 1,
        link: 'BasketBall',
        cName: 'navLink',
    },
    {
        id: 2,
        link: 'FootBall',
        cName: 'navLink',
    },
    {
        id: 3,
        link: 'BaseBall',
        cName: 'navLink',
    },
    {
        id: 4,
        link: 'Golf',
        cName: 'navLink',
    },
    {
        id: 5,
        link: 'Tennis',
        cName: 'navLink',
    },
    {
        id: 6,
        link: 'Rugby',
        cName: 'navLink',
    },
    // {
    //     id: 7,
    //     link: 'VoleyBall',
    //     cName: 'navLink',
    // },
    // {
    //     id: 8,
    //     link: 'Boxing',
    //     cName: 'navLink',
    // },


]