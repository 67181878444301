import React, { useState } from 'react'
// import { Col, Container, Row } from 'react-bootstrap'
import './style.css'
import { sidebarData } from './sidebarData'
import Modale from '../modal';
import Cookies from 'universal-cookie';
import { Badge } from 'react-bootstrap';
import Marquee from "react-fast-marquee";
import useViewport from '../../hook/useViewport'



export default function SideBar() {
    const [modalShow, setModalShow] = useState(false);
    const cookie = new Cookies();
    const { width } = useViewport();

    return (
        <>
            <div className='types'>
                {sidebarData?.map((data) =>
                    <div key={data.id} pill bg="info" className='typesBadge' onClick={() => setModalShow(true)}>
                        <p>{data.title}</p>
                    </div>
                )}
            </div>
            <Modale
                show={modalShow}
                onHide={() => setModalShow(false)}
                signin={cookie.get('token') ? null : true}
            />
        </>

    )
}
