import React from 'react'
// import axios from 'axios';
import { useState } from 'react'
// import { Col, Row } from 'react-bootstrap';
import moment from 'moment';
import Modale from '../modal';
import Marquee from "react-fast-marquee";
import './style.css'

// import Cookies from 'universal-cookie';

export default function NewsData({ signin, news, setNews }) {
    const [modalShow, setModalShow] = useState(false);

    return (
        <>
            <Marquee gradient={false} speed={80} pauseOnHover={true}>
                {news?.map((data, index) => (
                    <div key={index} className="newsDetailsContainer" onClick={() => setModalShow(true)}>
                        <div className='newsImageContainer' style={{ backgroundImage: `url(${data.enclosures[0].url})` }} alt="news"></div>
                        <div className='newsTextContainer'>
                            <p className="newsTitle" style={{ fontSize: 16, marginLeft: 16, fontWeight: 500, lineHeight: 1.3, height: '4rem', color: 'white' }}>{data.title}</p>
                            <div style={{ width: '100%', display: 'flex', alignItems: 'center', justifyContent: 'space-between', marginLeft: 16 }}>
                                <p style={{ fontSize: 14, marginLeft: 8, lineHeight: 1.3, color: '#ffffff', opacity: '0.5', marginBottom: 0 }}>{moment(data.published).format('dddd DD MMMM yyyy HH:MM')}</p>
                                <p style={{ fontSize: 14, lineHeight: 1.3, color: '#ffffff', marginBottom: 0, opacity: '0.5', marginRight: 8 }}>{data.category}</p>
                            </div>
                        </div>
                        {/* <div style={{ padding: 10 }} onClick={() => setModalShow(true)}>
                            <img style={{ borderRadius: 12, width: 350, height: 230 }} src={data.enclosures[0].url} alt="news" />
                            <p className="newsTitle" style={{ fontSize: 16, marginBottom: 12, lineHeight: 1.3, height: '4rem' }}>{data.title}</p>
                            <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between', margin: '0px 16px 0px 16px' }}>
                                <p style={{ fontSize: 12, marginTop: 8, lineHeight: 1.3, opacity: '50%', marginBottom: 0, width: 180 }}>{moment(data.published).format('dddd DD MMMM yyyy HH:MM')}</p>
                                <p style={{ fontSize: 12, marginTop: 8, lineHeight: 1.3, opacity: '50%', color: '#1C53A5', marginBottom: 0 }}>{data.category}</p>
                            </div>
                        </div> */}

                    </div>
                ))}
            </Marquee>
            <Modale
                show={modalShow}
                onHide={() => setModalShow(false)}
                signin={signin}
            />
        </>
    )
}
